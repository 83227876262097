import React from "react";
import { Grid, TextField, ButtonGroup } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { makeBackendAPIRequest } from "../api_utilities/backend";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
const useStyles = (theme) => ({
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: "100%",
    width: "100%",
  },
  Grid: {
    height: "100%",
    width: "100%",
  },
  button: {
    backgroundColor: theme.palette.primary.dark,
    // width: "20%",
  },
  buttonTwo: {
    backgroundColor: theme.palette.secondary.dark,
    // width: "20%",
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
});

class registerUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      password: null,
      confirmPassword: null,
      error: null,
      loading: false,
    };
  }
  routeChange = () => {
    let path = "/sign-in";
    this.props.history.push(path);
  };
  handleChangeUsername = (e) => {
    this.setState({ userName: e.target.value });
  };
  handleChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };
  handleChangeConfirmPassword = (e) => {
    this.setState({ confirmPassword: e.target.value });
  };

  handleSubmit = () => {
    const { userName, password, confirmPassword } = this.state;
    const { store, history } = this.props;
    if (password !== confirmPassword) {
      alert("Passwords don't match");
    } else {
      makeBackendAPIRequest({
        url: "user/create_user/",
        method: "POST",
        access_token: null,
        body: {
          username: userName,
          password: password,
        },

        onSuccessCallback: () => {
          history.push("/sign-in");
        },
      });
    }
  };

  render() {
    const { userName, password, confirmPassword, error, loading } = this.state;
    const { classes, history } = this.props;

    return (
      <Grid className={classes.Grid}>
        <Card className={classes.card}>
          <Grid container>
            <Grid item lg={3} />
            <Grid item container lg={6} justifyContent={"center"}>
              <TextField
                name={userName}
                required
                fullWidth
                label="Username"
                autoFocus
                onChange={this.handleChangeUsername}
              />
              <TextField
                required
                fullWidth
                type="password"
                label="Password"
                name={password}
                onChange={this.handleChangePassword}
              />
              <TextField
                required
                fullWidth
                label="Confirm Password"
                name={confirmPassword}
                onChange={this.handleChangeConfirmPassword}
              />
            </Grid>
            <Grid item lg={3} />
          </Grid>
        </Card>
        <Card className={classes.card}>
          <Grid container>
            <Grid item lg={3} />
            <Grid item container lg={6} justifyContent={"center"}>
              {loading ? (
                <CircularProgress />
              ) : (
                <ButtonGroup
                  fullWidth
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button
                    variant="contained"
                    className={classes.button}
                    size="large"
                    onClick={this.handleSubmit}
                    disabled={!userName}
                    disabled={!password}
                    disabled={!confirmPassword}
                  >
                    Register
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.buttonTwo}
                    size="large"
                    onClick={this.routeChange}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              )}
            </Grid>
            <Grid item lg={3} />
          </Grid>
        </Card>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(observer(withRouter(registerUser)));
