import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import { CssBaseline, Container, Box } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TicTacGoStore from "./store/TicTacGoStore";
import { observer } from "mobx-react";
import './App.css'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      store: new TicTacGoStore(),
    };
  }

  render() {
    const { store } = this.state;
    const theme = createTheme({
      palette: {
        type: "dark",
        primary: {
          main: "#7DF9FF",
          secondary: "#E60000",
        },
      },
      overrides: {
        MuiButton: {
          contained: { "&:hover": { color: "#000000" } },
        },
        MuiIconButton: { root: { "&:focus": { outline: "none" } } },
      },
    });

    const accessToken = store.accessToken;

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container>
          <Box mt={4}>
            <Router>
              <Route
                path="/"
                render={() =>
                  !accessToken ? (
                    <Login store={store} />
                  ) : (
                    <Dashboard store={store} />
                  )
                }
              />
            </Router>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

export default observer(App);
