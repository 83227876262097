import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, LinearProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { makeBackendAPIRequest } from "../api_utilities/backend";
import { observer } from "mobx-react";
import GameBoard from "./GameBoard";
import GameLeaderboard from "./GameLeaderboard";

const useStyles = (theme) => ({
  tall: {
    minHeight: "100%",
  },
  spinner: {
    marginTop: theme.spacing(5),
  },
  linearProgress: {
    display: "inherit",
  },
  progressBar: {
    transition: "none",
  },
});

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boards: [],
      game: {
        boards_won: 0,
        boards_lost: 0,
        boards_drawn: 0,
        boards_incomplete: 0,
        is_complete: false,
      },
      moveMade: false,
      lastMoveTime: new Date().getTime(),
      timeToMakeMove: 5000,
      timeRemaining: 5000,
      badMovePlayed: false,
      emptyBoardsPollInterval: null,
      gameStatePollInterval: null,
      moveTimeoutInterval: null,
      gameID: new URLSearchParams(this.props.location.search).get("gameID"),
    };
  }

  makeMove = (ordinal) => {
    const { boards } = this.state;
    const { store } = this.props;
    const activeBoard = boards.shift();
    this.setState({
      boards: boards,
      lastMoveTime: new Date().getTime(),
      timeToMakeMove: 1500,
      badMovePlayed:
        ordinal == null || activeBoard.board_encoding[ordinal] !== "",
    });
    if (activeBoard == null && ordinal == null) {
      return;
    }
    makeBackendAPIRequest({
      url: "game/move/",
      method: "POST",
      access_token: store.accessToken,
      body: {
        id: activeBoard["id"],
        cell_index: ordinal,
      },
      onSuccessCallback: (data) => {
        if (Object.keys(data).length !== 0) {
          this.setState((prevState) => {
            return {
              boards: prevState.boards.concat(data),
            };
          });
        }
      },
    });
  };

  getSpinner = () => {
    const { classes } = this.props;
    const { timeToMakeMove } = this.state;

    if (timeToMakeMove !== 5000) {
      this.setState({ timeToMakeMove: 5000 });
    }

    return (
      <Grid container>
        <Grid
          item
          container
          lg={12}
          justifyContent={"center"}
          className={classes.spinner}
        >
          <CircularProgress size={"60vh"} />
        </Grid>
      </Grid>
    );
  };

  getProgressBar = () => {
    const { timeRemaining, timeToMakeMove } = this.state;
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid
          item
          container
          lg={12}
          sx={{ width: "100%" }}
          justifyContent={"center"}
          className={classes.linearProgress}
        >
          <LinearProgress
            style={{ width: "100%" }}
            variant="determinate"
            color="secondary"
            value={(100 * timeRemaining) / timeToMakeMove}
            classes={{ bar: classes.progressBar }}
          />
        </Grid>
      </Grid>
    );
  };

  componentDidMount() {
    const { store } = this.props;
    const { gameID } = this.state;

    makeBackendAPIRequest({
      url: "game/request_boards/",
      method: "GET",
      access_token: store.accessToken,
      onSuccessCallback: (data) => {
        if (Object.keys(data).length !== 0) {
          this.setState({
            boards: data,
          });
        }
      },
    });

    const emptyBoardsPollInterval = setInterval(() => {
      const { boards } = this.state;
      if (boards.length === 0) {
        makeBackendAPIRequest({
          url: "game/request_boards/",
          method: "GET",
          access_token: store.accessToken,
          onSuccessCallback: (data) => {
            if (Object.keys(data).length !== 0) {
              this.setState({
                boards: data,
              });
            }
          },
        });
      }
    }, 500);

    makeBackendAPIRequest({
      url: "game/get_game_state/",
      method: "GET",
      access_token: store.accessToken,
      searchParams: {
        game_id: gameID,
      },
      onSuccessCallback: (data) => {
        this.setState({
          game: data,
        });
      },
    });

    const gameStatePollInterval = setInterval(
      () =>
        makeBackendAPIRequest({
          url: "game/get_game_state/",
          method: "GET",
          access_token: store.accessToken,
          searchParams: {
            game_id: gameID,
          },
          onSuccessCallback: (data) => {
            this.setState({
              game: data,
            });
          },
        }),
      3000
    );

    const moveTimeoutInterval = null;
    setInterval(() => {
      const { lastMoveTime, timeToMakeMove } = this.state;
      const milliseconds = new Date().getTime();

      if (milliseconds - lastMoveTime > timeToMakeMove) {
        this.makeMove(null);
      } else {
        this.setState({
          timeRemaining: timeToMakeMove + lastMoveTime - milliseconds,
        });
      }
    }, 20);

    this.setState({
      emptyBoardsPollInterval: emptyBoardsPollInterval,
      gameStatePollInterval: gameStatePollInterval,
      moveTimeoutInterval: moveTimeoutInterval,
    });
  }

  componentWillUnmount() {
    const {
      emptyBoardsPollInterval,
      gameStatePollInterval,
      moveTimeoutInterval,
    } = this.state;

    clearInterval(emptyBoardsPollInterval);
    clearInterval(gameStatePollInterval);
    clearInterval(moveTimeoutInterval);
  }

  componentDidUpdate() {
    const { moveMade } = this.state;

    if (moveMade) {
      this.setState({ moveMade: false, lastMoveTime: new Date().getTime() });
    }
  }

  render() {
    const { classes, history } = this.props;
    const { boards, game, gameID } = this.state;

    if (game.is_complete) {
      const p = new URLSearchParams();
      p.set("gameID", gameID);
      history.push(`/spectate?${p.toString()}`);
    }

    return (
      <Grid container className={classes.tall}>
        {boards.length > 0 ? (
          <GameBoard
            onCellClick={this.makeMove}
            board={boards[0]}
            containerHeight={70}
          />
        ) : (
          this.getSpinner()
        )}
        {this.getProgressBar()}
        <GameLeaderboard
          boardsWon={game.boards_won}
          boardsDrawn={game.boards_drawn}
          boardsIncomplete={game.boards_incomplete}
          boardsLost={game.boards_lost}
        />
      </Grid>
    );
  }
}
export default withStyles(useStyles)(observer(withRouter(Game)));
