import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, Typography, Card } from "@material-ui/core";

import { makeBackendAPIRequest } from "../api_utilities/backend";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";

const useStyles = (theme) => ({
  fillHeight: {
    height: "100%",
  },
  card: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: "100%",
    width: "100%",
    position: "relative",
  },
  leaderboardIcon: {
    margin: theme.spacing(1),
  },
  spinner: {
    marginTop: theme.spacing(5),
  },
  bgText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    margin: 0,
    opacity: 0.25,
  },
  piece: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    margin: 0,
    opacity: 1,
  },
  gameBoardContainer: {
    marginTop: theme.spacing(6),
    paddingBottom: "33vh",
    display: "flex",
    height: "100vh",
  },
  boardCellCard: {
    // padding: theme.spacing(2),
    width: "100%",
    position: "relative",
    margin: theme.spacing(1),
  },
});

class Queue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInGame: false,
      gameID: null,
      queueInterval: null,
    };
  }

  componentDidMount = () => {
    const { store } = this.props;

    const queueInterval = setInterval(
      () =>
        makeBackendAPIRequest({
          url: "game/queue/",
          method: "GET",
          access_token: store.accessToken,
          onSuccessCallback: (data) => {
            this.setState({
              isInGame: data.is_in_game,
              gameID: data.game_id,
            });
          },
        }),
      3000
    );

    this.setState({ queueInterval: queueInterval });
  };

  componentWillUnmount = () => {
    const { queueInterval } = this.state;

    clearInterval(queueInterval);
  };

  render() {
    const { isInGame, gameID } = this.state;
    const { classes, history } = this.props;

    if (!isInGame) {
      return (
        <Grid container>
          <Grid item lg={5} />
          <Grid
            item
            container
            lg={2}
            justifyContent={"center"}
            className={classes.spinner}
          >
            {" "}
            <CircularProgress />
          </Grid>
          <Grid item lg={5} />
        </Grid>
      );
    } else {
      const p = new URLSearchParams();
      p.set("gameID", gameID);
      history.push(`/game?${p.toString()}`);
      return <Grid container />;
    }
  }
}
export default withStyles(useStyles)(observer(withRouter(Queue)));
