import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { makeBackendAPIRequest } from "../api_utilities/backend";
import { observer } from "mobx-react";
import UserList from "./UserList";

const useStyles = (theme) => ({
  card: {
    padding: theme.spacing(2),
    width: "100%",
    height: "100%",
    margin: theme.spacing(1),
    minHeight: "50vh",
  },
});

class Rankings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount = () => {
    const { store } = this.props;

    makeBackendAPIRequest({
      url: "user/get_user_rankings/",
      method: "GET",
      access_token: store.accessToken,
      onSuccessCallback: (data) => {
        this.setState({
          users: data,
        });
      },
    });
  };

  render() {
    const { users } = this.state;
    const { classes } = this.props;

    return (
      <Grid container wrap="nowrap">
        <Grid container lg={12}>
          <Card className={classes.card}>
            <Grid container lg={12} justifyContent={"center"}>
              <UserList users={users} />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(observer(Rankings));
