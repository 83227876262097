import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const useStyles = (theme) => ({
  card: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: "100%",
    width: "100%",
    position: "relative",
  },
  bgText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    margin: 0,
    opacity: 0.25,
    letterSpacing: 0,
  },
});

class GameLeaderboard extends React.Component {
  getLeaderboardIcon = (color, i) => {
    return <CheckBoxOutlineBlankIcon color={color} key={i} />;
  };

  render() {
    const { classes, boardsWon, boardsDrawn, boardsIncomplete, boardsLost } =
      this.props;

    return (
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item container lg={3}>
          <Card className={classes.card}>
            {Array.from(Array(boardsWon).keys()).map((i) =>
              this.getLeaderboardIcon("primary", i)
            )}
            <Typography variant="h4" className={classes.bgText}>
              Victories
            </Typography>
          </Card>
        </Grid>
        <Grid item container lg={3}>
          <Card className={classes.card}>
            {Array.from(Array(boardsDrawn).keys()).map((i) =>
              this.getLeaderboardIcon("disabled", i)
            )}
            <Typography variant="h4" className={classes.bgText}>
              Draws
            </Typography>
          </Card>
        </Grid>
        <Grid item container lg={3}>
          <Card className={classes.card}>
            {Array.from(Array(boardsIncomplete).keys()).map((i) =>
              this.getLeaderboardIcon("inherit", i)
            )}
            <Typography variant="h4" className={classes.bgText}>
              ?
            </Typography>
          </Card>
        </Grid>
        <Grid item container lg={3}>
          <Card className={classes.card}>
            {Array.from(Array(boardsLost).keys()).map((i) =>
              this.getLeaderboardIcon("secondary", i)
            )}
            <Typography variant="h4" className={classes.bgText}>
              Defeats
            </Typography>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(GameLeaderboard);
