import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { makeBackendAPIRequest } from "../api_utilities/backend";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import GameList from "./GameList";

const useStyles = (theme) => ({
  card: {
    padding: theme.spacing(2),
    width: "100%",
    height: "100%",
    margin: theme.spacing(1),
    minHeight: "50vh",
  },
  button: {
    backgroundColor: theme.palette.secondary.dark,
  },
});

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      games: [],
      activeGamesPollInterval: null,
    };
  }

  componentDidMount = () => {
    const { store } = this.props;

    makeBackendAPIRequest({
      url: "game/get_active_games/",
      method: "GET",
      access_token: store.accessToken,
      onSuccessCallback: (data) => {
        this.setState({
          games: data,
        });
      },
    });

    const activeGamesPollInterval = setInterval(() => {
      makeBackendAPIRequest({
        url: "game/get_active_games/",
        method: "GET",
        access_token: store.accessToken,
        onSuccessCallback: (data) => {
          this.setState({
            games: data,
          });
        },
      });
    }, 1000);

    this.setState({ activeGamesPollInterval: activeGamesPollInterval });
  };

  componentWillUnmount = () => {
    const { activeGamesPollInterval } = this.state;

    clearInterval(activeGamesPollInterval);
  };

  render() {
    const { games } = this.state;
    const { classes, history } = this.props;

    return (
      <Grid container wrap="nowrap">
        <Grid container lg={6}>
          <Card className={classes.card}>
            <Grid container lg={12} justifyContent={"center"}>
              <Grid container item lg={4} />
              <Grid container item lg={4}>
                <Button
                  variant="contained"
                  className={classes.button}
                  size="large"
                  fullWidth
                  onClick={() => {
                    history.push("/queue");
                  }}
                >
                  Play Ranked
                </Button>
              </Grid>
              <Grid container item lg={4} />
            </Grid>
          </Card>
        </Grid>
        <Grid container lg={6}>
          <Card className={classes.card}>
            <GameList games={games} nullText="no active games to spectate" />
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(observer(withRouter(Profile)));
