import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import { observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import { withRouter } from "react-router-dom";

const useStyles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.primary.dark,
  },
  link: {
    textDecoration: "none",
  },
  menu: { marginTop: theme.spacing(6) },
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
    };
  }

  handleClose = () => {
    this.setState({ isMenuOpen: false });
    let path = "/profile";
    this.props.history.push(path);
  };

  handleOpen = (e) => {
    this.setState({ isMenuOpen: true, anchorEl: e.currentTarget });
  };

  render() {
    const { classes, store, history } = this.props;
    const { isMenuOpen, anchorEl } = this.state;

    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="caption" className={classes.title}>
            tic &#8226; tac &#8226; go
          </Typography>

          <Button onClick={this.handleOpen}>
            <MenuIcon />
          </Button>

          <Menu
            open={isMenuOpen}
            onClose={this.handleClose}
            anchorEl={anchorEl}
            className={classes.menu}
          >
            <MenuItem
              onClick={() => {
                history.push("/lobby");
                this.setState({ isMenuOpen: false });
              }}
            >
              Lobby
            </MenuItem>
            <MenuItem onClick={this.handleClose}>Profile</MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/rankings");
                this.setState({ isMenuOpen: false });
              }}
            >
              Rankings
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                store.setAccessToken(null);
                let path = "/";
                history.push(path);
              }}
            >
              <MeetingRoomIcon /> Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(useStyles)(observer(withRouter(Header)));
