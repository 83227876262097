import React from "react";
import { Grid, TextField, ButtonGroup } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { makeBackendAPIRequest } from "../api_utilities/backend";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
const useStyles = (theme) => ({
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(5),
    height: "100%",
    width: "100%",
  },
  Grid: {
    height: "100%",
    width: "100%",
  },
  button: {
    backgroundColor: theme.palette.primary.dark,
    // width: "20%",
  },
  buttonTwo: {
    backgroundColor: theme.palette.secondary.dark,
    // width: "20%",
  },

  margin: {
    marginBottom: theme.spacing(4),
  },
  smallSpacing: {
    padding: theme.spacing(3),
  },
});

class signIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      password: null,
      error: null,
      loading: false,
    };
  }
  authentication = () => {
    const { store, history } = this.props;
    const { userName, password } = this.state;

    makeBackendAPIRequest({
      url: "api/token/",
      method: "POST",
      access_token: null,
      body: {
        username: userName,
        password: password,
      },
      onSuccessCallback: (data) => {
        store.setAccessToken(data.access);
        history.push("/");
      },
    });
  };
  handleChangeUsername = (e) => {
    this.setState({ userName: e.target.value });
  };
  handleChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };
  routeChange = () => {
    let path = "/register";
    this.props.history.push(path);
  };
  render() {
    const { userName, password, confirmPassword, error, loading } = this.state;
    const { classes, history } = this.props;

    return (
      <Grid className={classes.Grid}>
        <Card className={classes.card}>
          <Grid container>
            <Grid item lg={3} />
            <Grid
              item
              container
              lg={6}
              justifyContent={"center"}
              className={classes.margin}
            >
              <TextField
                name={userName}
                required
                fullWidth
                label="Username"
                autoFocus
                onChange={this.handleChangeUsername}
                style={{
                  maxWidth: "500px",
                  maxHeight: "50px",
                  minWidth: "500px",
                  minHeight: "50px",
                }}
              />
              <TextField
                name={password}
                required
                fullWidth
                label="Password"
                type="password"
                onChange={this.handleChangePassword}
                style={{
                  maxWidth: "500px",
                  maxHeight: "50px",
                  minWidth: "500px",
                  minHeight: "50px",
                }}
              />
            </Grid>
            <Grid item lg={3} />
          </Grid>

          <Grid container>
            <Grid item lg={3} />
            <Grid item container lg={6} justifyContent={"center"}>
              {loading ? (
                <CircularProgress />
              ) : (
                <Grid justifyContent={"space-between"}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    size="large"
                    onClick={this.authentication}
                    style={{
                      maxWidth: "200px",
                      maxHeight: "50px",
                      minWidth: "200px",
                      minHeight: "50px",
                    }}
                  >
                    SignIn
                  </Button>
                  <Button
                    size="large"
                    onClick={this.routeChange}
                    style={{
                      maxWidth: "200px",
                      maxHeight: "50px",
                      minWidth: "200px",
                      minHeight: "50px",
                    }}
                  >
                    Create Account
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item lg={3} />
          </Grid>
        </Card>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(observer(withRouter(signIn)));
