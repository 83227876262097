import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  LinearProgress,
  Link,
} from "@material-ui/core";
import { makeBackendAPIRequest } from "../api_utilities/backend";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import GameBoard from "./GameBoard";

const useStyles = (theme) => ({
  boardSection: {
    width: "100%",
  },
  allBoards: {
    margin: theme.spacing(1),
  },
  sectionCard: {
    width: "100%",
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.5), 0px 1px 1px 0px rgba(0,0,0,0.38), 0px 1px 3px 0px rgba(0,0,0,0.34)",
  },
  winnerCard1: {
    borderStyle: "dashed",
    borderWidth: "3px",
    borderColor: theme.palette.secondary.dark,
  },
  winnerCard2: {
    borderStyle: "dashed",
    borderWidth: "3px",
    borderColor: theme.palette.primary.dark,
  },
  updating: {
    width: "100%",
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
  },
  userLink: {
    cursor: "pointer",
  },
});

class Spectate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      game: { boards: [] },
      gameID: new URLSearchParams(this.props.location.search).get("gameID"),
      gameStatePollInterval: null,
    };
  }

  componentDidMount = () => {
    const { store } = this.props;
    const { gameID } = this.state;

    makeBackendAPIRequest({
      url: "game/spectate/",
      method: "GET",
      searchParams: {
        game_id: gameID,
      },
      access_token: store.accessToken,
      onSuccessCallback: (data) => {
        this.setState({ game: data });
      },
    });

    const gameStatePollInterval = setInterval(
      () =>
        makeBackendAPIRequest({
          url: "game/spectate/",
          method: "GET",
          searchParams: {
            game_id: gameID,
          },
          access_token: store.accessToken,
          onSuccessCallback: (data) => {
            this.setState({ game: data });
          },
        }),
      500
    );

    this.setState({
      gameStatePollInterval: gameStatePollInterval,
    });
  };

  componentWillUnmount = () => {
    const { gameStatePollInterval } = this.state;

    clearInterval(gameStatePollInterval);
  };

  render() {
    const { classes, history } = this.props;
    const { game } = this.state;
    const {
      player_1,
      player_1_id,
      player_1_starting_elo,
      player_1_boards_won,
      player_2,
      player_2_id,
      player_2_starting_elo,
      player_2_boards_won,
      winner_player_ordinal,
      elo_change,
      is_finished,
      boards,
    } = game;

    const p1_boards = boards.filter((board) => {
      return board.player_piece === "x";
    });
    const p2_boards = boards.filter((board) => {
      return board.player_piece === "o";
    });
    const neutral_boards = boards.filter((board) => {
      return board.player_piece === "?" || board.player_piece === "=";
    });

    const p1Link = new URLSearchParams();
    p1Link.set("userID", player_1_id);
    const p2Link = new URLSearchParams();
    p2Link.set("userID", player_2_id);

    return (
      <Grid container>
        {!is_finished ? (
          <Grid container item lg="12">
            <LinearProgress
              className={classes.updating}
              color={
                player_1_boards_won >= player_2_boards_won
                  ? "secondary"
                  : "primary"
              }
            />
          </Grid>
        ) : null}
        <Grid container item lg="6">
          <Card
            className={`${classes.sectionCard} ${
              winner_player_ordinal === 1 ? classes.winnerCard1 : ""
            }`}
          >
            <Grid container>
              <Grid container item lg="12" justifyContent="center">
                <Link
                  variant="h6"
                  color="inherit"
                  underline={"none"}
                  onClick={() => {
                    history.push(`/profile?${p1Link.toString()}`);
                  }}
                  className={classes.userLink}
                >
                  {player_1}
                </Link>
              </Grid>
              <Grid container item lg="12">
                <Typography variant="subtitle">
                  ELO: {player_1_starting_elo}{" "}
                  {!is_finished || elo_change === 0
                    ? null
                    : winner_player_ordinal === 1
                    ? `(+${elo_change})`
                    : winner_player_ordinal == null &&
                      player_1_starting_elo <= player_2_starting_elo
                    ? `(+${elo_change})`
                    : `(-${elo_change})`}
                </Typography>
              </Grid>
              <Grid container item lg="12" justifyContent="center">
                <Typography variant="h1" color="secondary">
                  {player_1_boards_won}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid container item lg="6">
          <Card
            className={`${classes.sectionCard} ${
              winner_player_ordinal === 2 ? classes.winnerCard2 : ""
            }`}
          >
            <Grid container>
              <Grid container item lg="12" justifyContent="center">
                <Link
                  variant="h6"
                  color="inherit"
                  underline={"none"}
                  onClick={() => {
                    history.push(`/profile?${p2Link.toString()}`);
                  }}
                  className={classes.userLink}
                >
                  {player_2}
                </Link>
              </Grid>
              <Grid container item lg="12">
                <Typography variant="subtitle">
                  ELO: {player_2_starting_elo}{" "}
                  {!is_finished || elo_change === 0
                    ? null
                    : winner_player_ordinal === 2
                    ? `(+${elo_change})`
                    : winner_player_ordinal == null &&
                      player_2_starting_elo <= player_1_starting_elo
                    ? `(+${elo_change})`
                    : `(-${elo_change})`}
                </Typography>
              </Grid>
              <Grid container item lg="12" justifyContent="center">
                <Typography variant="h1" color="primary">
                  {player_2_boards_won}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {boards.length > 0 ? (
          <Grid
            container
            lg={12}
            className={classes.allBoards}
            alignItems="flex-start"
            justifyContent="center"
            spacing={2}
          >
            <Grid
              container
              item
              lg={neutral_boards.length > 0 ? "4" : "6"}
              spacing={1}
              className={classes.boardContainer}
            >
              {p1_boards.map((board) => {
                return (
                  <Grid item lg="6" className={classes.boardSection}>
                    <GameBoard
                      onCellClick={() => {}}
                      board={board}
                      containerHeight={15}
                    />
                  </Grid>
                );
              })}
            </Grid>
            {neutral_boards.length > 0 ? (
              <Grid
                container
                item
                lg="4"
                spacing={1}
                className={classes.boardContainer}
              >
                {neutral_boards.map((board) => {
                  return (
                    <Grid item lg="6" className={classes.boardSection}>
                      <GameBoard
                        onCellClick={() => {}}
                        board={board}
                        containerHeight={15}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}
            <Grid
              container
              item
              lg={neutral_boards.length > 0 ? "4" : "6"}
              spacing={1}
              className={classes.boardContainer}
            >
              {p2_boards.map((board) => {
                return (
                  <Grid item lg="6" className={classes.boardSection}>
                    <GameBoard
                      onCellClick={() => {}}
                      board={board}
                      containerHeight={15}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    );
  }
}
export default withStyles(useStyles)(observer(withRouter(Spectate)));
