import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, ButtonGroup, Card } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { makeBackendAPIRequest } from "../api_utilities/backend";
import { v4 as uuidv4 } from "uuid";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Register from "./Register";
import SignIn from "./SignIn";
import randomWords from "random-words";

const useStyles = (theme) => ({
  button: {
    backgroundColor: theme.palette.primary.dark,
  },
  spacing: {
    padding: theme.spacing(25),
  },
  smallSpacing: {
    padding: theme.spacing(3),
  },
  buttonRight: {
    top: "50%",
    height: 30,
    float: "right",
    position: "relative",
    transform: "translateY(-50%)",
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, loading: false };
  }

  routeChange = () => {
    let path = "/sign-in";
    this.props.history.push(path);
  };

  render = () => {
    const { error, loading } = this.state;
    const { store, history, classes } = this.props;

    if (error) {
      return <div>{error}</div>;
    }

    return (
      <Grid container className={classes.spacing}>
        <Route
          exact
          path="/register"
          render={() => <Register store={store} />}
        />
        <Route exact path="/sign-in" render={() => <SignIn store={store} />} />

        <Route
          exact
          path="/"
          render={() => (
            <Grid container>
              <Grid item lg={4} />
              <Grid item lg={4} container justify="center">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Grid container>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => {
                        this.setState({ loading: true });
                        const guestUsername =
                          randomWords(3)
                            .map((word) => {
                              return (
                                word.charAt(0).toUpperCase() + word.substr(1)
                              );
                            })
                            .join("") + Math.floor(Math.random() * 100000);
                        const guestPassword = uuidv4();
                        makeBackendAPIRequest({
                          url: "user/create_user/",
                          method: "POST",
                          access_token: null,
                          body: {
                            username: guestUsername,
                            password: guestPassword,
                          },
                          onSuccessCallback: () => {
                            makeBackendAPIRequest({
                              url: "api/token/",
                              method: "POST",
                              access_token: null,
                              body: {
                                username: guestUsername,
                                password: guestPassword,
                              },
                              onSuccessCallback: (data) => {
                                store.setAccessToken(data.access);
                                history.push("/");
                              },
                            });
                          },
                        });
                      }}
                      fullWidth={true}
                      style={{
                        maxWidth: "300px",
                        maxHeight: "90px",
                        minWidth: "300px",
                        minHeight: "90px",
                      }}
                    >
                      Play as Guest
                    </Button>
                    <Grid className={classes.smallSpacing} />
                    <Button
                      variant="contained"
                      size="large"
                      onClick={this.routeChange}
                      fullWidth={true}
                      style={{
                        maxWidth: "300px",
                        maxHeight: "90px",
                        minWidth: "300px",
                        minHeight: "90px",
                      }}
                    >
                      Log in
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid item lg={4} />
            </Grid>
          )}
        />
      </Grid>
    );
  };
}

export default withStyles(useStyles)(withRouter(Login));
