import React from "react";
import {
  Grid,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TextField,
  TablePagination,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { makeBackendAPIRequest } from "../api_utilities/backend";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import GameList from "./GameList";

const useStyles = (theme) => ({
  card: {
    padding: theme.spacing(2),
    width: "100%",
    height: "100%",
    margin: theme.spacing(1),
  },

  Grid: {
    height: "100%",
    width: "100%",
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  dividerTwo: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  dividerThree: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dividerFour: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
  },
  button: {
    backgroundColor: theme.palette.primary.dark,
  },
  spacing: {
    padding: theme.spacing(2),
  },
  buttonRight: {
    top: "50%",
    height: 30,
    float: "right",
    position: "relative",
    transform: "translateY(-50%)",
  },
});

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      userInfo: null,
      editing: false,
      games: [],
      userID: new URLSearchParams(this.props.location.search).get("userID"),
    };
  }

  componentDidMount = () => {
    const { store } = this.props;
    const { userID } = this.state;

    const params = {
      url: "user/get_user_info/",
      method: "GET",
      access_token: store.accessToken,
      onSuccessCallback: (data) => {
        this.setState({
          userName: data.username,
          userInfo: data.tictacgoinfo,
          games: data.games,
        });
      },
    };

    if (userID != null) {
      params.searchParams = {
        user_id: userID,
      };
    }

    makeBackendAPIRequest(params);
  };

  handleEdit = () => {
    this.setState({ editing: true });
  };

  handleChange = (e) => {
    this.setState({ userName: e.target.value });
  };

  handleSave = (e) => {
    this.setState({ editing: false });
    const { store } = this.props;
    const { userName } = this.state;
    makeBackendAPIRequest({
      url: "user/set_username/",
      method: "POST",
      access_token: store.accessToken,
      body: { username: userName },
    });
  };

  render() {
    const { userName, userInfo, editing, games, userID } = this.state;
    const { classes } = this.props;
    if (!userInfo) {
      return <CircularProgress />;
    }

    return (
      <Grid container wrap="nowrap">
        <Grid container lg={6}>
          <Card className={classes.card}>
            <Grid container>
              {!editing ? (
                <Grid item container justifyContent="right">
                  <Grid item lg={11}>
                    <Typography variant="h5">{userName}</Typography>
                  </Grid>
                  <Grid item lg={1} justify="flex-end">
                    {userID == null ? (
                      <IconButton
                        onClick={() => {
                          this.handleEdit();
                        }}
                        className={classes.buttonRight}
                        variant="contained"
                      >
                        <EditIcon />
                        Edit
                      </IconButton>
                    ) : null}
                  </Grid>
                </Grid>
              ) : (
                <Grid item container>
                  <TextField
                    name={userName}
                    required
                    label="Username"
                    autoFocus
                    onChange={this.handleChange}
                  />
                  <Grid item lg={9} />
                  <Button
                    variant="contained"
                    size="large"
                    disabled={!userName}
                    onClick={() => {
                      this.handleSave();
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              )}
            </Grid>

            <Divider className={classes.divider} />

            <Typography variant="h6"> ELO: {userInfo.elo}</Typography>
            <Divider className={classes.dividerTwo} />
            <Typography variant="h6">
              Game Wins: {userInfo.game_wins}
            </Typography>
            <Typography variant="h6">
              Game Losses: {userInfo.game_losses}
            </Typography>
            <Typography variant="h6">
              Game Draws: {userInfo.game_draws}
            </Typography>
            <Divider className={classes.dividerThree} />
            <Typography variant="h6">
              Board Wins: {userInfo.board_wins}
            </Typography>
            <Typography variant="h6">
              Board Losses: {userInfo.board_losses}
            </Typography>
            <Typography variant="h6">
              Board Draws: {userInfo.board_draws}
            </Typography>
            <Divider className={classes.divider} />
          </Card>
        </Grid>
        <Grid container lg={6}>
          <Card className={classes.card}>
            <GameList
              games={games.sort((g1, g2) => {
                return g2.id - g1.id;
              })}
              nullText="no match history"
            />
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(observer(withRouter(Profile)));
