import React from "react";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

const useStyles = (theme) => ({
  winner1Cell: {
    color: theme.palette.secondary.main,
  },
  winner2Cell: {
    color: theme.palette.primary.main,
  },
  updating: {
    width: "100%",
    margin: theme.spacing(2),
  },
  row: {
    cursor: "pointer",
  },
});

class GameList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 20,
    };
  }

  handleChangePage = (_, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  handleOnClickRow = (gameID) => {
    const { history } = this.props;
    const p = new URLSearchParams();
    p.set("gameID", gameID);
    history.push(`/spectate?${p.toString()}`);
  };

  render() {
    const { page, rowsPerPage } = this.state;
    const { games, classes, nullText } = this.props;

    if (games.length === 0) {
      return (
        <Grid container>
          <Grid item container justifyContent="center" lg={12}>
            <Typography variant="h6">{nullText}</Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>Player 1</TableCell>
                <TableCell>Player 2</TableCell>
                <TableCell>Score</TableCell>
                <TableCell align="right">&#128710;Elo</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {games != null
                ? games
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((game) => {
                      return (
                        <TableRow
                          onClick={() => {
                            this.handleOnClickRow(game.id);
                          }}
                          className={classes.row}
                        >
                          <TableCell
                            className={
                              game.winner_player_ordinal === 1
                                ? classes.winner1Cell
                                : null
                            }
                          >{`${game.player_1.slice(0, 15)} (${
                            game.player_1_starting_elo
                          })`}</TableCell>
                          <TableCell
                            className={
                              game.winner_player_ordinal === 2
                                ? classes.winner2Cell
                                : null
                            }
                          >{`${game.player_2.slice(0, 15)} (${
                            game.player_2_starting_elo
                          })`}</TableCell>
                          <TableCell>
                            {game.player_1_boards_won} -{" "}
                            {game.player_2_boards_won}
                          </TableCell>
                          <TableCell align="right">
                            +/- {game.elo_change}
                          </TableCell>
                          <TableCell>
                            {!game.is_finished ? (
                              <LinearProgress
                                className={classes.updating}
                                color={
                                  game.player_1_boards_won >=
                                  game.player_2_boards_won
                                    ? "secondary"
                                    : "primary"
                                }
                              />
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={games != null ? games.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Grid>
    );
  }
}
export default withStyles(useStyles)(withRouter(GameList));
