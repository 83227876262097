import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Route } from "react-router-dom";
import Header from "./Header";
import Profile from "./Profile";
import Game from "./Game";
import Spectate from "./Spectate";
import Rankings from "./Rankings";
import Lobby from "./Lobby";
import Queue from "./Queue";
import { observer } from "mobx-react";

const useStyles = (theme) => ({
  mainContent: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
  },
});

class Dashboard extends React.Component {
  render() {
    const { classes, handleLogout, store } = this.props;

    return (
      <Grid container>
        <Route
          exact
          path={["/profile", "/queue", "/spectate", "/lobby", "/rankings", "/"]}
          render={() => <Header handleLogout={handleLogout} store={store} />}
        />
        <Grid container className={classes.mainContent}>
          <Route
            exact
            path={["/lobby", "/"]}
            render={() => <Lobby store={store} />}
          />
          <Route path="/profile" render={() => <Profile store={store} />} />
          <Route exact path="/queue" render={() => <Queue store={store} />} />
          <Route path="/game" render={() => <Game store={store} />} />
          <Route path="/spectate" render={() => <Spectate store={store} />} />
          <Route path="/rankings" render={() => <Rankings store={store} />} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(observer(Dashboard));
