import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { makeObservable, observable, action } from "mobx"

class TicTacGoStore {
  accessToken = null;

  constructor() {
    // makeObservable(this, {
    //   accessToken: observable,
    //   setAccessToken: action
    // })

    makeAutoObservable(this);

    makePersistable(this, {
      name: "TicTacGoStore",
      properties: ["accessToken"],
      storage: window.localStorage,
    });
  }

  setAccessToken = (accessToken) => {
    this.accessToken = accessToken;
  };
}

export default TicTacGoStore;
