import React from "react";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

const useStyles = (theme) => ({
  winner1Cell: {
    color: theme.palette.secondary.main,
  },
  winner2Cell: {
    color: theme.palette.primary.main,
  },
  updating: {
    width: "100%",
    margin: theme.spacing(2),
  },
  row: {
    cursor: "pointer",
  },
});

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 20,
    };
  }

  handleChangePage = (_, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  handleOnClickRow = (userID) => {
    const { history } = this.props;
    const p = new URLSearchParams();
    p.set("userID", userID);
    history.push(`/profile?${p.toString()}`);
  };

  render() {
    const { page, rowsPerPage } = this.state;
    const { users, classes, nullText } = this.props;

    if (users.length === 0) {
      return (
        <Grid container>
          <Grid item container justifyContent="center" lg={12}>
            <Typography variant="h6">{nullText}</Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} />
                <TableCell align="center" colSpan={3}>
                  Games
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  Boards
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell align="right">Elo</TableCell>
                <TableCell align="right">W</TableCell>
                <TableCell align="right">L</TableCell>
                <TableCell align="right">D</TableCell>
                <TableCell align="right">W</TableCell>
                <TableCell align="right">L</TableCell>
                <TableCell align="right">D</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users != null
                ? users
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user) => {
                      return (
                        <TableRow
                          onClick={() => {
                            this.handleOnClickRow(user.id);
                          }}
                          className={classes.row}
                        >
                          <TableCell>{user.username}</TableCell>
                          <TableCell align="right">
                            {user.tictacgoinfo.elo}
                          </TableCell>
                          <TableCell align="right">
                            {user.tictacgoinfo.game_wins}
                          </TableCell>
                          <TableCell align="right">
                            {user.tictacgoinfo.game_losses}
                          </TableCell>
                          <TableCell align="right">
                            {user.tictacgoinfo.game_draws}
                          </TableCell>
                          <TableCell align="right">
                            {user.tictacgoinfo.board_wins}
                          </TableCell>
                          <TableCell align="right">
                            {user.tictacgoinfo.board_losses}
                          </TableCell>
                          <TableCell align="right">
                            {user.tictacgoinfo.board_draws}
                          </TableCell>
                        </TableRow>
                      );
                    })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={users != null ? users.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Grid>
    );
  }
}
export default withStyles(useStyles)(withRouter(UserList));
