import queryString from "query-string";

const backendBaseURL = process.env.REACT_APP_BACKEND_BASE_URL;

const makeBackendAPIRequest = (params) => {
  const fetchOptions = {
    method: params.method,
    headers: {
      Authorization: "Bearer " + params.access_token,
    },
  };

  if (params.body) {
    fetchOptions.body = queryString.stringify(params.body);
    fetchOptions.headers["Content-Type"] = "application/x-www-form-urlencoded";
  }

  const url = new URL(backendBaseURL + "/" + params.url);
  if (params.searchParams) {
    Object.entries(params.searchParams).forEach((entry) => {
      const [key, value] = entry;
      url.searchParams.append(key, value);
    });
  }

  fetch(url, fetchOptions)
    .then((response) => {
      if (response.status === 500) {
        throw new Error(JSON.stringify(response));
      } else if (response.status === 401) {
        // replace with call to store
        localStorage.removeItem("fb_auth_access_token");
      } else {
        return response.json();
      }
    })
    .then((data) => {
      if (data.error) {
        if (params.onFailureCallback) {
          params.onFailureCallback();
        }

        return;
      }
      if (params.onSuccessCallback) {
        params.onSuccessCallback(data);
      }
    })
    .catch((e) => {
      if (!params.triesAttempted) {
        params.triesAttempted = 0;
      }
      if (params.triesAttempted < 0 && params.method === "GET") {
        params.triesAttempted += 1;
        setTimeout(() => {
          makeBackendAPIRequest(params);
        }, 1000);
      } else if (params.onErrorCallback) {
        params.onErrorCallback(e);
      }
    });
};

export { makeBackendAPIRequest };
