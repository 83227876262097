import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card } from "@material-ui/core";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const useStyles = (theme) => ({
  playerPiece: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    margin: 0,
    padding: 0,
    opacity: 0.55,
    fontSize: (props) => `${props.containerHeight * 1.15}vh`,
    zIndex: -1,
    letterSpacing: 0,
    fontWeight: 500,
  },
  piece: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    margin: 0,
    opacity: 1,
    letterSpacing: 0,
    fontSize: (props) => `${props.containerHeight * 0.25}vh`,
    fontWeight: 900,
  },
  gameBoardContainer: {
    height: (props) => `${props.containerHeight}vh`,
    width: "100%",
    position: "relative",
  },
  boardCellCard: {
    width: "100%",
    position: "relative",
    margin: (props) => theme.spacing(props.containerHeight / 70),
    opacity: 0.8,
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.5), 0px 1px 1px 0px rgba(0,0,0,0.38), 0px 1px 3px 0px rgba(0,0,0,0.34)",
  },
});

class GameBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      badMovePlayed: false,
    };
  }

  getGameCell = (piece, ordinal) => {
    const { classes, onCellClick } = this.props;
    return (
      <Card
        className={classes.boardCellCard}
        onClick={() => {
          onCellClick(ordinal);
        }}
      >
        <Typography
          variant="h1"
          className={classes.piece}
          color={piece === "o" ? "primary" : "secondary"}
        >
          {piece === "o" ? "O" : piece === "x" ? "X" : ""}
        </Typography>
      </Card>
    );
  };

  render() {
    const { badMovePlayed } = this.state;
    const { classes, board } = this.props;

    const boardEncoding = board.board_encoding;
    const playerPiece = board.player_piece;

    return (
      <TransitionGroup
        component={null}
        transitionName="moveMadeTransition"
        exit={false}
      >
        <CSSTransition
          key={board.id}
          timeout={500}
          classNames={badMovePlayed ? "badMoveMade" : "moveMade"}
          unmountOnExit
        >
          <Grid
            container
            spacing={2}
            wrap="nowrap"
            className={classes.gameBoardContainer}
            justifyContent={"center"}
          >
            <Grid item container lg={4}>
              <Grid item container lg={12}>
                {this.getGameCell(boardEncoding[0], 0)}
              </Grid>
              <Grid item container lg={12}>
                {this.getGameCell(boardEncoding[3], 3)}
              </Grid>
              <Grid item container lg={12}>
                {this.getGameCell(boardEncoding[6], 6)}
              </Grid>
            </Grid>
            <Grid item container lg={4}>
              <Grid item container lg={12}>
                {this.getGameCell(boardEncoding[1], 1)}
              </Grid>
              <Grid item container lg={12}>
                {this.getGameCell(boardEncoding[4], 4)}
              </Grid>
              <Grid item container lg={12}>
                {this.getGameCell(boardEncoding[7], 7)}
              </Grid>
            </Grid>
            <Grid item container lg={4}>
              <Grid item container lg={12}>
                {this.getGameCell(boardEncoding[2], 2)}
              </Grid>
              <Grid item container lg={12}>
                {this.getGameCell(boardEncoding[5], 5)}
              </Grid>
              <Grid item container lg={12}>
                {this.getGameCell(boardEncoding[8], 8)}
              </Grid>
            </Grid>
            <Typography
              className={classes.playerPiece}
              color={
                playerPiece === "o"
                  ? "primary"
                  : playerPiece === "x"
                  ? "secondary"
                  : "disabled"
              }
            >
              {playerPiece === "o"
                ? "O"
                : playerPiece === "x"
                ? "X"
                : playerPiece}
            </Typography>
          </Grid>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}
export default withStyles(useStyles)(GameBoard);
